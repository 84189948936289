.components-home-page {
    background: #05132d url('images/background/accueil/components-home.jpg') no-repeat scroll 700px -290px;
    background-size: 75%;

    @media screen and (max-width: 2300px) {
        background-size: 80% auto;
        background-position: 520px -290px;
    }

    @media screen and (max-width: 2200px) {
        background-size: 100%;
        background-position: 80px -400px;
    }

    @media screen and (max-width: 2100px) {
        background-position: 80px -380px;
    }

    @media screen and (max-width: 2000px) {
        background-position: 80px -340px;
    }

    @media screen and (max-width: 1800px) {
        background-position: 60px -260px;
    }

    @media screen and (max-width: 1600px) {
        background-size: 120%;
        background-position: -200px -320px;
    }

    @media screen and (max-width: 1500px) {
        background-size: 110%;
        background-position: -80px -200px;
    }

    @media screen and (max-width: 1400px) {
        background-size: 110%;
        background-position: -80px -150px;
    }

    @media screen and (max-width: 1300px) {
        background-size: 120%;
        background-position: -180px -150px;
    }

    @media screen and (max-width: 1200px) {
        background-size: 120%;
        background-position: -160px -100px
    }

    @media screen and (max-width: 1100px) {
        background-size: 130%;
        background-position: -260px -80px;
    }

    @include respond-to('t-992') {
        background-position: -220px -88px;
    }

    @media screen and (max-width: 856px) {
        background-size: 140%;
        background-position: -300px -20px;
    }

    @include respond-to('m-768') {
        background-size: 1200px;
        background-position: right -86px;
    }

    @media screen and (max-width: 675px) {
        background-size: 1200px;
        background-position: right -40px;
    }

    @media screen and (max-width: 646px) {
        background-size: 1200px;
        background-position: right -10px;
    }

    @media screen and (max-width: 386px) {
        background-position: right 56px;
    }


    .page-content {
        @include flex(column, stretch, space-between);
        position: relative;

        .top {
            color: #ffffff;
            padding: 40px 40px 160px 40px;

            @media screen and (max-width: 700px) {
                padding: 40px 40px 200px 40px;
            }

            p {
                @include respond-to('t-992') {
                    @include flex();
                    max-width: 480px;
                }
            }
        }

        .bottom {
            background-color: #ffffff;

            .bottom-container {
                gap: 40px;
                @include flex-row-to-column('t-992', flex-start, flex-start);

                @include respond-to('t-992') {
                    gap: 0;
                }

                .text {
                    padding: 40px 0 40px 40px;
                    flex: 2 0 40%;

                    @include respond-to('t-992') {
                        padding: 40px;
                    }

                    h2 {
                        margin-top: 0;
                    }

                    .link {
                        margin: 0;

                        a {
                            text-decoration: none;
                        }
                    }
                }

                .container-map {
                    padding: 40px 40px 40px 0;

                    @include respond-to('t-992') {
                        padding: 0;
                    }

                    p {
                        margin: 0;
                        @include flex();

                        img {
                            border: none;
                            width: 100%;
                        }

                        &.legend {
                            @include flex();
                            padding-top: 16px;
                            gap: 16px;
                            flex-wrap: wrap;

                            @include respond-to('t-992') {
                                padding: 32px 40px 40px 40px;
                            }

                            > span {
                                @include flex(row, center, center);
                                gap: 8px;

                                img {
                                    width: 10px;
                                    height: auto;
                                }

                                span {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
