.integrations-marketplaces {
    background: url('images/background/integrations-marketplaces.jpg');
    background-size: cover;

    .page-content {

        a {
            text-decoration: none;
        }

        .section-1 {
            padding: 40px;
            color: #ffffff;

            .container {
                @include flex(column);

                .subtitle {
                    display: block;
                    width: fit-content;
                    padding: 6px 32px;
                    border-radius: 36px;
                    background-color: rgba(195, 22, 50, 0.6);
                }

                .cta-connect {
                    margin-bottom: 0;
                    padding-top: 24px;
                }
            }
        }

        .section-2 {
            padding: 40px;
            background-color: #ffffff;

            h2 {
                margin-top: 0;
            }

            .flex-grid-container {
                padding-top: 16px;
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                grid-column-gap: 24px;
                grid-row-gap: 16px;

                @media screen and (max-width: 1160px) {
                    grid-template-columns: repeat(5, 1fr);
                }

                @include respond-to('t-992') {
                    grid-template-columns: repeat(4, 1fr);
                }

                @include respond-to('m-768') {
                    grid-template-columns: repeat(3, 1fr);
                }

                @media screen and (max-width: 610px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                p {
                    margin: 0;
                    @include flex(column, center, center);

                    a {
                        @include flex(column, center, center);
                        text-decoration: none;

                        img {
                            width: 80px;
                            height: 80px;
                        }

                        span {
                            text-decoration: underline;
                            text-decoration-color: rgba(0, 0, 0, 0.38);
                        }
                    }
                }
            }
        }

        .section-3 {
            background: url('images/background/integrations-marketplaces.jpg');
            background-position-y: 50%;
            background-size: 100%;
            padding: 40px;
            color: #ffffff;
            overflow: hidden;

            @include respond-to('m-768') {
                background-size: cover;
            }

            .container {
                h2 {
                    margin-top: 0;
                }

                p {
                    gap: 8px;
                    @include flex-row-to-column('m-768', center, flex-start);

                    a {
                        flex-shrink: 0;
                        display: block;
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        border-radius: 50%;
                        padding: 16px;
                        background-color: rgba(195, 22, 50, 0.5);
                        color: #ffffff;
                        text-decoration: underline;
                        text-decoration-color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }
    }
}
