.main-home {
    background: url('images/background/accueil/main-home.jpg');
    background-size: cover;

    .page-content {
        .pseudo-card {
            border-radius: $bdrs-normal;
        }

        .padding {
            padding: 40px;
        }

        .vertical-spacer {
            padding: 20px 0;
        }

        .cta {
            margin: 0;
        }

        h2 {
            margin-top: 0;
        }

        .brush {
            background-image: url('images/illustrations/line-brush.svg');
            background-size: cover;
        }

        .brush-2 {
            background-image: url('images/illustrations/line-brush-2.svg');
            background-size: cover;
        }

        .blue-bg {
            background-color: #0a2540;
            color: #f4f3f7;
        }

        .white-bg {
            background-color: #ffffff;
        }

        .heading {
            padding: 40px;

            @media screen and (max-width: 400px) {
                padding: 40px 0;
            }


            h1, h2 {
                margin: 0;
                color: #ffffff;

                @media screen and (max-width: 400px) {
                    padding: 0 40px;
                }
            }

            h1 {
                padding-bottom: 80px;

                @include respond-to('m-768') {
                    padding-bottom: 56px;
                }
            }

            h2 {
                padding-bottom: 24px;
            }

            .card-container {
                @include flex(row, stretch, flex-start);
                gap: 40px;

                @include respond-to('t-992') {
                    flex-wrap: wrap;
                }

                .pseudo-card {
                    @include flex(column, stretch, space-between);
                    padding: 40px;
                    background: rgba(31, 68, 113, 0.7);
                    flex: 1 3 33%;

                    @include respond-to('t-992') {
                        flex: 1 3 34%;
                    }

                    p {
                        margin: 0;
                        padding-bottom: 40px;
                        color: #ffffff;

                        & + p {
                            padding-bottom: 0;

                            a {
                                display: flex;
                                width: 100%;
                                text-decoration: none;

                                md-elevated-button {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .send {
            background: #ffffff;
            padding: 40px;

            @include respond-to('m-768') {
                padding: 40px 0 0 0;
            }

            h2 {
                margin-top: 0;

                @include respond-to('m-768') {
                    padding: 0 40px;
                }
            }

            .bdrs {
                border-radius: $bdrs-normal;

                @include respond-to('m-768') {
                    box-shadow: initial;
                    border-radius: initial;
                }

                &:has(.oops) {
                    box-shadow: initial;
                    border-radius: initial;
                    width: fit-content;

                    @include respond-to('m-768') {
                        padding-left: 40px;
                    }
                }
            }
        }

        .customer-care {
            .container-customer-care {
                display: grid;
                grid-template-columns: 1fr 3fr;
                grid-gap: 40px;

                @include respond-to('d-1024') {
                    grid-template-columns: 1fr 2fr;
                }

                @include respond-to('m-768') {
                    display: block;
                }

                .illustration {
                    @include respond-to('m-768') {
                        display: none;
                    }

                    p {
                        margin: 0;

                        img {
                            width: 75%;
                        }
                    }
                }
            }
        }

        .pricing {
            .pricing-container {
                display: grid;
                grid-template-columns: 2fr 1fr;
                grid-gap: 40px;

                @include respond-to('d-1024') {
                    grid-template-columns: 1fr 1fr;
                }

                @include respond-to('m-768') {
                    display: block;
                }

                .illustration {
                    @include respond-to('m-768') {
                        display: none;
                    }

                    p {
                        margin: 0;

                        img {
                            width: 75%;
                        }
                    }
                }
            }
        }

        .insurance {
            .insurance-container {
                display: grid;
                grid-template-columns: 1fr 3fr;
                grid-gap: 40px;

                @include respond-to('d-1024') {
                    grid-template-columns: 1fr 2fr;
                }

                @include respond-to('m-768') {
                    display: block;
                }

                .illustration {
                    @include respond-to('m-768') {
                        display: none;
                    }

                    p {
                        margin: 0;

                        img {
                            width: 85%;
                        }
                    }
                }
            }
        }
    }
}
