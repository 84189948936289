@use "node_modules/@material/typography/mdc-typography";

html {
    --mdc-theme-primary: #17375f; // The theme primary color
    --mdc-theme-primary: #2f5f9a; // The theme primary color
    --mdc-theme-secondary: #ffc928; // The theme secondary color
    --mdc-theme-secondary: #F2B75B; // The theme secondary color
    --mdc-theme-surface: #ffffff; // The theme surface color.
    --mdc-theme-background: #f3f4f7; // The theme background color.

    --mdc-theme-on-primary: #ffffff; // Text and icons on top of a theme primary color background.
    --mdc-theme-on-secondary: #ffffff; // Text and icons on top of a theme secondary color background.
    //--mdc-theme-on-surface: #17375f; // Text and icons on top of a theme surface color background.


    --mdc-typography-font-family: Fira Sans, Roboto, Helvetica, sans-serif;
    --md-menu-item-label-text-font: Fira Sans, Roboto, Helvetica, sans-serif;

    --mdc-typography-headline1-font-size: 3.052rem;
    --mdc-typography-headline1-line-height: 1.3;
    --mdc-typography-headline1-font-weight: 400;
    --mdc-typography-headline1-letter-spacing: -0.017em;

    --mdc-typography-headline2-font-size: 2.441rem;
    --mdc-typography-headline2-line-height: 1.3;
    --mdc-typography-headline2-font-weight: 400;
    --mdc-typography-headline2-letter-spacing: -0.025em;

    --mdc-typography-headline3-font-size: 1.953rem;
    --mdc-typography-headline3-line-height: 1.3;
    --mdc-typography-headline3-letter-spacing: -0.009em;

    --mdc-typography-headline4-font-size: 1.563rem;
    --mdc-typography-headline4-line-height: 1.3;
    --mdc-typography-headline4-letter-spacing: -0.01em;

    --mdc-typography-headline5-font-size: 1.25rem;
    --mdc-typography-headline5-line-height: 1.3;
    --mdc-typography-headline5-letter-spacing: -0.007em;

    --mdc-typography-subtitle1-font-size: 1rem;
    --mdc-typography-subtitle1-line-height: 1.75;
    --mdc-typography-subtitle1-font-weight: 500;

    --mdc-typography-body1-font-size: 1rem;
    --mdc-typography-body1-line-height: 1.75;
    --mdc-typography-body1-letter-spacing: 0.004em;


    --mdc-typography-button-letter-spacing: 0.05em;

    --mdc-shape-small: 4px; // border radius
    --mdc-shape-medium: 12px;
    --mdc-shape-large: 16px;
    --mdc-icon-font: 'Material Icons Outlined'; // icons

    @include respond-to('t-992') {
        --mdc-typography-headline1-font-size: 2.488rem;
        --mdc-typography-headline1-font-weight: 500;
        --mdc-typography-headline1-letter-spacing: 0em;

        --mdc-typography-headline2-font-size: 2.074rem;
        --mdc-typography-headline2-font-weight: 500;
        --mdc-typography-headline2-letter-spacing: 0em;

        --mdc-typography-headline3-font-size: 1.728rem;
        --mdc-typography-headline3-font-weight: 500;
        --mdc-typography-headline3-letter-spacing: 0em;

        --mdc-typography-headline4-font-size: 1.44rem;
        --mdc-typography-headline4-font-weight: 500;
        --mdc-typography-headline4-letter-spacing: 0em;

        --mdc-typography-headline5-font-size: 1.2rem;
        --mdc-typography-headline5-font-weight: 500;
        --mdc-typography-headline5-letter-spacing: 0em;
    }

    @include respond-to('m-768') {
        --mdc-typography-headline1-font-size: 1.802rem;
        --mdc-typography-headline1-font-weight: 500;

        --mdc-typography-headline2-font-size: 1.602rem;
        --mdc-typography-headline2-font-weight: 500;

        --mdc-typography-headline3-font-size: 1.424rem;
        --mdc-typography-headline3-font-weight: 500;

        --mdc-typography-headline4-font-size: 1.266rem;
        --mdc-typography-headline4-font-weight: 500;

        --mdc-typography-headline5-font-size: 1.125rem;
        --mdc-typography-headline5-font-weight: 500;
    }
}
