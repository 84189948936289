@import "~@fontsource/fira-sans/latin-300.css";
@import "~@fontsource/fira-sans/latin-400.css";
@import "~@fontsource/fira-sans/latin-500.css";
@import "~@fontsource/fira-sans/latin-600.css";

/*
    import depuis package Fontsource 10/10/24
    icônes et symboles peuvent être utilisés avec les balises <md-icon>nom_de_l_icône</md-icon>
    ou directement avec la balise
    <span class="material-symbols-outlined">nom_de_l_icône</span>
    <span class="material-icons">nom_de_l_icône</span>
 */

.material-symbols-outlined {
    font-family: "Material Symbols Outlined";
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}
