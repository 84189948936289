:root {
    --md-elevated-button-label-text-font: 500 0.875rem/2.25rem Fira Sans, Roboto, Helvetica, sans-serif;
    --md-outlined-button-label-text-font: 500 0.875rem/2.25rem Fira Sans, Roboto, Helvetica, sans-serif;
    --md-filled-button-label-text-font: 500 0.875rem/2.25rem Fira Sans, Roboto, Helvetica, sans-serif;
    --md-text-button-label-text-font: 500 0.875rem/2.25rem Fira Sans, Roboto, Helvetica, sans-serif;
    --md-filled-tonal-button-label-text-font: 500 0.875rem/2.25rem Fira Sans, Roboto, Helvetica, sans-serif;

    --md-elevated-button-container-height: 36px;
    --md-outlined-button-container-height: 36px;
    --md-filled-button-container-height: 36px;
    --md-text-button-container-height: 36px;
    --md-filled-tonal-button-container-height: 36px;

    --md-elevated-button-container-shape: 4px;
    --md-outlined-button-container-shape: 4px;
    --md-filled-button-container-shape: 4px;
    --md-text-button-container-shape: 4px;
    --md-filled-tonal-button-container-shape: 4px;

    a:has(md-elevated-button),
    a:has(md-filled-button),
    a:has(md-filled-tonal-button),
    a:has(md-outlined-button),
    a:has(md-text-button) {
        text-decoration: none;
    }
}
