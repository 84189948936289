footer {
    .top {
        background-color: $color-bg;
        padding: 0 40px;

        .container {
            @include flex(row, flex-start, space-around);
            flex-wrap: wrap;
            padding: 32px 0;
            gap: 40px;

            @include respond-to('m-768') {
                gap: 0;
            }

            .column {
                width: calc(25% - 30px);

                @include respond-to('t-992') {
                    width: calc(50% - 30px);
                }

                @include respond-to('m-768') {
                    width: 100%;
                }

                .label:first-child {
                    margin-top: 0;

                    @include respond-to('m-768') {
                        margin-top: 1rem;
                    }
                }

                ul {
                    padding: 0;
                    margin: 0;

                    li {
                        padding: 0;
                        line-height: 1.75rem;
                        list-style-type: none;

                        a {
                            text-decoration: none;
                        }
                    }

                    &.socials {
                        @include flex(row, flex-start, flex-start);
                        padding-top: 24px;
                        gap: 8px;

                        a {
                            @include flex(row, center, center);
                            width: 32px;
                            height: 32px;
                            background-color: $color-main;
                            border-radius: 50%;

                            img {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
            }

        }
    }

    .bottom {
        background-color: $color-main;
        color: #ffffff;
        padding: 8px 40px;

        .container {
            ul {
                margin: 0;
                padding: 0;
                column-gap: 24px;
                flex-wrap: wrap;
                @include flex-row-to-column('m-768', flex-start, flex-start);

                @include respond-to('m-768') {
                    padding: 16px 0;
                }

                li {
                    list-style-type: none;
                    margin: 0;
                    padding: 8px 0;
                    white-space: nowrap;

                    @include respond-to('m-768') {
                        padding: 0;
                        line-height: 1.75;
                    }

                    a {
                        text-decoration: none;
                        color: #ffffff;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}
