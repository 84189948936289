/* Fonts ($ff-*) */
$ff-main                :   "Fira Sans", sans-serif;
$ff-icon                :   Ico;

/* Font-sizes ($fz-*) */
$fz-small               :   12px;
$fz-normal              :   14px;
$fz-normal-m            :   16px;
$fz-normal-l            :   18px;
$fz-heading-s           :   20px;
$fz-heading             :   24px;

$lh-normal              :   20px;

/* Colors ($color-*) */
$color-main-bg          :   #b0d0f8;
$color-dark-grey        :   #2b2b2b;
$color-main-l-1         :   #205d9e;
$color-main-l-2         :   #0095c5;
$color-main-l-3         :   #2fb0ec;
$color-bg               :   #f3f4f7;
$color-hr               :   #eaedf2;
$color-border           :   #e7e8e9;
$color-input-bd         :   #d0d4ea;
$color-grey-btn         :   #b3b5c5;
$color-dark-grey-btn    :   #6d7175;
$color-red              :   #c31f3d;
$color-red-l            :   #dd2345;
$color-yellow           :   #fff725;
$color-beige            :   #f2ead3;

/* couleurs à pousser pour les POs */
$color-main             :   #17375f;
$color-error            :   #c41532;
$color-success          :   #27ae60;
$color-warning          :   #F2971B;
$color-gray             :   #a7aabc;

/* new colors 03-2021 */
$color-button-disabled  :   #a5adba;
$color-field-disabled   :   rgba(0,0,0,.3);
$color-placeholder      :   rgba(0,0,0,.6);
$color-input-border     :   #dadad8;
$color-hover            :   #102540;
$color-hover-bg         :   rgba(0,0,0,.04);

/* Border-radius */

$bdrs-small             :   4px;
$bdrs-normal            :   4px;
$content-width          :   1140px;

/* content spacing */
$space-unit             :   4px;
$space-content          :   5 * $space-unit;

/* Media Queries */

$breakpoints: (
    'd-1310'      : (max-width: 1310px),
    'd-1024'      : (max-width: 1024px),
    't-992'       : (max-width: 992px),
    'm-768'       : (max-width: 768px),
    'm-374'       : (max-width: 374px),
);
