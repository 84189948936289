.blog {
    main {
        padding: 40px;

        .blog-container {
            display: grid;
            grid-template-columns: [article] 70fr [aside] 30fr;
            column-gap: 80px;

            @include respond-to('t-992') {
                display: block;
            }

            .blog-content {
                @include respond-to('t-992') {
                    width: 100%;
                    padding-bottom: 40px;
                }

                .blog-post-list {
                    display: grid;
                    gap: 40px;
                    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));


                    .post-item {
                        .post-container {
                            text-decoration: none;

                            .post-img {
                                margin: 0;
                                max-height: 177px;
                                overflow: hidden;

                                @include respond-to('t-992') {
                                    max-height: 140px;
                                }

                                @media only screen and (max-width: 894px) {
                                    max-height: 200px;
                                }

                                @include respond-to('m-768') {
                                    max-height: 177px;
                                }

                                @media only screen and (max-width: 614px) {
                                    max-height: 260px;
                                }

                                img {
                                    width: 100%;
                                }
                            }

                            .post-content {
                                background-color: #ffffff;

                                h2 {
                                    margin: 0;
                                    padding: 24px 0 8px;
                                }

                                .date {
                                    font-size: 14px;
                                    color: rgba(0, 0, 0, 0.6);
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .blog-paging {
            @include flex(row, center, center);
            gap: 8px;
            padding: 40px 0 0 0;
        }
    }
}

.blog-article {
    main {
        padding: 40px;

        .article-container {
            display: grid;
            grid-template-columns: [article] 70fr [aside] 30fr;
            column-gap: 80px;

            @include respond-to('t-992') {
                display: block;
            }

            .article-content {
                @include respond-to('t-992') {
                    width: 100%;
                    padding-bottom: 40px;
                }

                .thumb {
                    padding: 24px 0;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }


                img {
                    max-width: 100%;
                    margin-bottom: 24px;
                }

                .box-like {
                    background-color: $color-bg;
                    padding: 24px 32px;
                    margin: 24px 0 36px;
                }

                .related-article {
                    padding-top: 32px;
                    font-weight: bold;
                }

                .related-articles {
                    padding-top: 32px;

                    span {
                        font-weight: bold;
                    }

                    a {
                        display: list-item;
                        list-style-type: disc;
                        list-style-position: inside;
                        padding-left: 32px;
                    }
                }

                .sources {
                    padding-top: 32px;
                    font-weight: bold;

                    span {
                        display: list-item;
                        list-style-type: none;
                        font-weight: normal;
                    }
                }

                .article-footer {
                    padding: 32px 0 0 0;

                    @include respond-to('t-992') {
                        flex-direction: column;
                    }

                    .date {
                        color: rgba(0, 0, 0, 0.6);
                    }
                }
            }
        }
    }

    table {
        border-collapse: collapse;

        thead {
            background-color: $color-main;
            color: #ffffff;

            th {
                border: 1px solid transparent;
                border-right: 1px solid #ffffff;
                padding: 18px 8px;
            }
        }

        tbody {
            tr:nth-child(odd) {
                background-color: #f3f4f7;
            }

            td {
                padding: 18px 8px;
                border: 1px solid transparent
            }
        }
    }
}

.blog-header {
    @include flex(row, baseline, space-between);
    padding: 18px 0 40px;

    a {
        text-decoration-color: rgba(0, 0, 0, 0.3);
    }

    h1 {
        margin: 1rem 0;
    }
}

.blog-side-menu {
    align-self: start;
    background-color: $color-bg;
    padding: 40px;

    aside {
        p {
            margin: 0;
            text-align: center;

            @include respond-to('t-992') {
                text-align: left;

                &.cta {
                    text-align: right;

                    @include respond-to('m-768') {
                        text-align: center;
                    }
                }
            }
        }

        ul {
            padding: 32px 0 16px;
            margin: 0;

            li {
                @include flex(column, center, center);
                text-align: center;
                padding: 0 0 24px 0;
                gap: 8px;
                line-height: 24px;

                @include respond-to('t-992') {
                    @include flex(row, center, flex-start);
                    text-align: left;
                }

                .icon {
                    @include flex(row, center, center);
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: #ffffff;
                    flex-shrink: 0;
                }
            }
        }
    }
}