.send-package {
    background-color: #000a16;
    background-image: url('images/background/send-package.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    .page-content {
        .top {
            position: relative;
            padding: 40px;

            img {
                position: fixed;
                z-index: -1;
                top: 100px;
                right: 10%;
                width: 80px;
            }

            h1, p {
                color: #ffffff;
            }

            h1 {
                margin: 3rem 0 0;
            }

            p {

                display: block;
                width: fit-content;
                padding: 6px 32px;
                border-radius: 36px;
                background-color: rgba(195, 22, 50, 0.7);

                &.top1 {
                    margin-left: 80px;

                    @include respond-to('m-374') {
                        margin-left: 60px;
                    }
                }

                &.top2 {
                    margin-left: 0px;
                }

                &.top3 {
                    margin-left: 60px;

                    @include respond-to('m-768') {
                        margin-left: 40px;
                    }

                    @include respond-to('m-374') {
                        margin-left: 20px;
                    }
                }

                &.top4 {
                    margin-left: 100px;

                    @include respond-to('m-768') {
                        margin-left: 70px;
                    }

                    @include respond-to('m-374') {
                        margin-left: 40px;
                    }
                }

                &.top5 {
                    margin-left: 0px;
                }
            }
        }

        .funnel {
            background-color: #ffffff;

            &:has(.oops) {
                background-color: transparent;
                padding: 0 40px 40px 40px;
            }

            .container-iframe-funnel {
                width: 100%;
                max-width: 1200px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .bottom {
            color: #ffffff;
            padding: 40px;
            background-color: #001435;

            .bottom-title {
                margin-top: 0;
            }

            .flex {
                @include flex(row, flex-start, space-between);
                gap: 40px;

                @include respond-to('t-992') {
                    flex-direction: column;
                    gap: 0;
                }

                p {
                    width: 50%;

                    @include respond-to('m-768') {
                        width: 100%;
                    }
                }

                .carriers {
                    @include respond-to('t-992') {
                        @include flex(row, center, flex-end);
                        padding-top: 32px;
                    }

                    @include respond-to('m-768') {
                        width: 100%;
                    }

                    ul {
                        @include flex(row, center, space-between);
                        flex-wrap: wrap;
                        gap: 40px;
                        margin: 0;
                        padding: 0;

                        @media only screen and (max-width: 1090px) {
                            gap: 16px;
                        }

                        @include respond-to('t-992') {
                            align-self: flex-end;
                        }

                        @include respond-to('m-768') {
                            max-width: initial;
                            justify-content: space-between;
                            align-items: center;
                        }

                        li {
                            list-style-type: none;
                            width: 80px;
                            height: 80px;
                            padding: 0;
                            background-color: #ffffff;
                            border-radius: 50%;
                            overflow: hidden;
                            @include flex(row, center, center);

                            img {
                                max-width: 60px;
                                height: auto;

                                &.monr {
                                    max-width: 65%;
                                }

                                &.sogp {
                                    max-width: 55%;
                                }

                                &.dhle {
                                    max-width: 110%;
                                }

                                &.upse {
                                    max-width: 60%;
                                }

                                &.copr {
                                    max-width: 60%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
