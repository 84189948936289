.team {
    .intro {
        padding: 40px 80px;

        @include respond-to('t-992') {
            padding: 40px;
        }

        .padding {
            padding: 0 40px;

            @include respond-to('t-992') {
                padding: 0;
            }
        }
    }

    .lead {
        padding: 0 80px 40px;

        @include respond-to('t-992') {
            padding: 0;
        }

        .padding {
            padding: 40px;
            background-color: #eaedf2;

            .container-pictures {
                padding: 40px 0;
                gap: 40px;
                @include flex(row, stretch, space-around);

                @include respond-to('t-992') {
                    flex-direction: column;
                }

                .container-picture {
                    background-color: #ffffff;
                    border-radius: $bdrs-normal;
                    overflow: hidden;
                    max-width: 350px;

                    @include flex(column, stretch, stretch);

                    @include respond-to('t-992') {
                        max-width: initial;
                    }

                    .img {
                        margin: 0;

                        @include respond-to('t-992') {
                            text-align: center;
                        }

                        img {
                            @include respond-to('t-992') {
                                max-width: 100%;
                            }
                        }
                    }

                    .text {
                        padding: 40px;

                        p {
                            margin: 0;

                            & + p {
                                padding-top: 16px;

                                @include respond-to('t-992') {
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}