#data-tracking-banner {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 2;
    color: rgba(0, 0, 0, 0.87);
    align-items: center;
    background-color: #fff;

    .banner-container {
        padding: 40px;

        @include respond-to('m-768') {
            padding: 24px;
        }

        .deny-mobile {
            display: none;
            visibility: hidden;

            @include respond-to('m-768') {
                display: inherit;
                visibility: visible;
                text-align: right;
                text-decoration: underline;
                text-decoration-color: rgba(0, 0, 0, 0.38);
                cursor: pointer;
                margin-top: 0;
            }
        }

        .banner-title {
            margin-top: 0;
        }

        .actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 16px;
            flex-wrap: wrap;
            width: 100%;
            padding-top: 40px;

            @include respond-to('m-768') {
                padding-top: 0;
                gap: 8px;
            }

            .customize {
                margin: 0;

                a {
                    text-decoration: none;
                }
            }
        }
    }
}

.hide-for-mobile {
    @include respond-to('m-768') {
        display: none;
        visibility: hidden;
    }
}
