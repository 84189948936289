@import "src/assets/scss/abstract/variables";

html {
    height: 100%;
    font-size: 100%;

    > * {
        box-sizing: border-box;
    }

    body {
        height: 100%;
        color: rgba(0, 0, 0, 0.87);
        font-family: $ff-main;

        .site-content {
            height: 100%;
            @include flex(column);

            .page-content {
                flex: 1 0 auto;
            }

            footer {
                flex-shrink: 0;
            }
        }

        h1, h2, h3, h4, h5 {
            margin: 3rem 0 1.38rem;
            line-height: 1.3;
        }

        h1 {
            margin-top: 0;
        }

        p {
            line-height: 1.75;
            margin: 1rem 0;
        }

        ul {
            padding: 0 0 0 48px;
            margin: 1rem 0;
            list-style: disc;

            @include respond-to('m-768') {
                padding: 0 0 0 16px;
            }

            li {
                line-height: 1.75;
                padding-left: 8px;

                a {
                    word-wrap: break-word;
                }
            }
        }

        a {
            color: rgba(0, 0, 0, 0.87);
            text-decoration-color: rgba(0, 0, 0, 0.38);
            cursor: pointer;
        }

        textarea, select, input {
            background-color: #fff;
            border: 0;
            outline: none;
            -webkit-appearance: none;
        }

        input:-webkit-autofill,
        select:-webkit-autofill,
        textarea:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 100px transparent inset;
            -webkit-background-clip: text;
        }

        audio,
        canvas,
        iframe,
        img,
        svg,
        video {
            vertical-align: middle;
        }
    }
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

