.nowrap {
    white-space: nowrap;
}

.container-small {
    width: 100%;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
}

.container {
    width: 100%;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
}

.container-extended {
    width: 100%;
    max-width: 1270px;
    margin-left: auto;
    margin-right: auto;
}
