.top-nav {
    @include flex(row, center, space-between);
    min-height: 56px;
    z-index: 2;
    background: #ffffff;

    a {
        text-decoration: none;

        &.nowrap {
            white-space: nowrap;
        }
    }

    p, ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
        padding: 0;
    }

    .logo {
        padding: 0 40px;

        @include respond-to('m-768') {
            padding: 0 16px 0 32px;
        }

        @include respond-to('m-374') {
            padding: 0 16px;
        }

        img {
            width: 160px;
            height: auto;
        }
    }

    .navigation {
        padding: 0 40px;
        height: 100%;
        @include flex(row, center);

        @include respond-to('t-992') {
            padding: 0 16px;
        }

        @include respond-to('m-374') {
            padding: 0 4px;
        }

        .text-navigation {
            @include flex(row, center);
            gap: 24px;
            height: 100%;
            margin: 0;
            padding: 0;

            @media screen and (max-width: 1094px) {
                display: none;
            }

            li {
                @include flex(row, center);
                min-height: 32px;
                border-top: 2px solid transparent;
                border-bottom: 2px solid transparent;

                &:hover {
                    border-bottom: 2px solid $color-main;
                }
            }
        }

        .button-navigation {
            padding-left: 24px;

            @include respond-to('m-768') {
                padding-left: 0;
            }

            .buttons {
                height: 100%;
                @include flex(row, center);
                gap: 16px;

                @include respond-to('m-768') {
                    display: none;
                }
            }

            .icon-buttons {
                display: none;
                align-items: center;
                gap: 8px;

                @include respond-to('m-768') {
                    display: flex;
                }
            }
        }

        .burger-menu-container {
            display: none;
            padding-left: 16px;

            @media screen and (max-width: 1094px) {
                display: block;
            }

            #burger-navigation {
                /* local override of theme */
                --md-menu-container-color: #ffffff;
            }
        }
    }
}
