.carriers-main-page {
    .page-content {
        padding: 0 0 40px 0;

        header {
            background-image: url('images/background/carriers.jpeg');
            background-size: cover;
            background-position-x: right;
            background-color: #17375f;
            color: #ffffff;

            .intro {
                padding: 40px;

                h1 {
                    padding-bottom: 24px;
                }

                .arguments {
                    @include flex(column, flex-start, flex-start);

                    p {
                        gap: 8px;
                        margin: 0;
                        padding-bottom: 8px;
                        @include flex(row, flex-start, flex-start);

                        &:last-child {
                            padding-bottom: 0;
                        }

                        md-icon,
                        span {
                            display: inline-block;
                            min-height: 28px;
                            line-height: 28px;


                        }
                    }
                }
            }

            .funnel {
                padding: 0 40px 40px 40px;

                @include respond-to('m-768') {
                    padding: 0 0 40px 0;
                }

                .bdrs {
                    background-color: #fff;
                    border-radius: $bdrs-normal;

                    @include respond-to('m-768') {
                        border-radius: initial;
                    }
                }
            }
        }

        .carriers {
            padding: 40px;

            form {
                padding-bottom: 40px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 40px;

                @include respond-to('m-768') {
                    grid-template-columns: initial;
                    grid-template-rows: 1fr 1fr;
                    grid-gap: 32px;
                }

                .custom-select {
                    position: relative;

                    &:after {
                        content: '';
                        display: block;
                        pointer-events: none;
                        width: 8px;
                        height: 8px;
                        border-bottom: 2px solid rgba(0, 0, 0, 0.87);
                        border-right: 2px solid rgba(0, 0, 0, 0.87);
                        transform: rotate(45deg);
                        position: absolute;
                        right: 16px;
                        top: 20px;
                    }
                }

                select {
                    width: 100%;
                    padding: 16px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.87);
                }
            }

            ul {
                margin: 0;
                padding: 0;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                //row-gap: 40px;
                //column-gap: 40px;

                @include respond-to('t-992') {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include respond-to('m-768') {
                    grid-template-columns: 1fr;
                }

                li {
                    list-style-type: none;
                    padding: 0;

                    &[data-order^="item-"] {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.37);
                    }

                    &[data-order="item-1"],
                    &[data-order="item-4"],
                    &[data-order="item-7"],
                    &[data-order="item-10"],
                    &[data-order="item-13"] {
                        padding: 20px 20px 20px 0;
                        border-right: 1px solid rgba(0, 0, 0, 0.37);
                    }

                    &[data-order="item-2"],
                    &[data-order="item-5"],
                    &[data-order="item-8"],
                    &[data-order="item-11"],
                    &[data-order="item-14"] {
                        padding: 20px;
                        border-right: 1px solid rgba(0, 0, 0, 0.37);
                    }

                    &[data-order="item-3"],
                    &[data-order="item-6"],
                    &[data-order="item-9"],
                    &[data-order="item-12"] {
                        padding: 20px 0 20px 20px;
                    }

                    @include respond-to('t-992') {
                        &[data-order^="item-"] {
                            border-right: none;
                            padding: 0;
                        }

                        &[data-order="item-1"],
                        &[data-order="item-3"],
                        &[data-order="item-5"],
                        &[data-order="item-7"],
                        &[data-order="item-9"],
                        &[data-order="item-11"],
                        &[data-order="item-13"] {
                            padding: 20px 20px 20px 0;
                            border-right: 1px solid rgba(0, 0, 0, 0.37);
                        }

                        &[data-order="item-2"],
                        &[data-order="item-4"],
                        &[data-order="item-6"],
                        &[data-order="item-8"],
                        &[data-order="item-10"],
                        &[data-order="item-12"],
                        &[data-order="item-14"] {
                            padding: 20px 0 20px 20px;
                        }

                    }

                    @include respond-to('m-768') {
                        &[data-order^="item-"] {
                            border-right: none;
                            border-bottom: none;
                            padding: 0 0 40px 0;
                        }
                    }

                    a {
                        text-decoration: none;
                        height: 100%;
                        @include flex(column, flex-start, space-between);

                        .carrier-text {
                            h2 {
                                margin-top: 0;
                            }

                            .logo {
                                img {
                                    max-height: 40px;
                                }
                            }
                        }

                        ul {
                            display: initial;

                            li {
                                padding: 0;
                                list-style-type: disc;
                                list-style-position: inside;
                                border: none !important;
                            }
                        }

                        .more {
                            @include flex(row, center, flex-start);
                            gap: 8px;
                        }
                    }

                    &.hide {
                        display: none;
                    }
                }
            }
        }

        .partners {
            padding: 40px;
            background-color: #f3f4f7;

            h2 {
                margin-top: 0;
            }
        }

        .ecom {
            padding: 40px;

            h2 {
                margin-top: 0;
            }

            ul {
                padding: 24px 0 0 0;
                margin: 0;
                list-style: none;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 40px;
                grid-row-gap: 24px;
                align-items: start;

                @include respond-to('m-768') {
                    display: initial;
                }

                li {
                    @include flex(column, flex-start, flex-start);

                    @include respond-to('m-768') {
                        padding-bottom: 16px;
                    }

                    .head {
                        @include flex(row, center, center);
                        padding-bottom: 8px;

                        md-icon {
                            color: #1E5FA6;

                            @include respond-to('m-768') {
                                display: none;
                            }
                        }

                        b {
                            padding-left: 8px;

                            @include respond-to('m-768') {
                                padding-left: initial;
                            }
                        }
                    }

                    .text {
                        padding-left: 34px;

                        @include respond-to('m-768') {
                            padding-left: initial;
                        }
                    }
                }
            }

            .cta {
                margin: 0;
                padding-top: 40px;
            }
        }

        .specific-features {
            padding: 40px;
            background-color: #f3f4f7;

            h2 {
                margin-top: 0;
            }

            .text {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 40px;
                align-items: start;

                @include respond-to('m-768') {
                    grid-template-columns: 1fr;
                }

                .list {
                    span {
                        display: list-item;
                        list-style-position: inside;
                    }
                }
            }
        }
    }
}

.carrier-single-page {
    .padding {
        padding: 40px;
    }

    .vertical-spacer {
        padding: 20px 0;
    }

    h2 {
        margin-top: 0;
    }

    header {
        background: rgb(37, 122, 193);
        background: radial-gradient(circle at 86% 20%, rgb(37, 122, 193), #0a2540 72%);
        color: #f4f3f7;

        .background-illustration {
            background: url('images/illustrations/carriers/send.svg') no-repeat;
            background-position: 60% 40%;
            background-size: 70%;

            @include respond-to('d-1310') {
                background-position: 40% 30%;
            }

            @include respond-to('d-1024') {
                background-position: 40% 20%;
            }

            @include respond-to('m-768') {
                background-position: -32% 10%;
            }

            h1 {
                @include flex(row, center, flex-start);
                gap: 40px;

                @include respond-to('m-768') {
                    gap: 16px;
                }

                .logo {
                    @include flex(row, center, center);
                    flex-shrink: 0;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    background-color: #ffffff;
                    box-shadow: -5px 5px 17px -6px rgba(0, 0, 0, 0.33);
                    overflow: hidden;

                    @include respond-to('m-768') {
                        width: 56px;
                        height: 56px;
                    }

                    img {
                        width: 60%;

                        &.DLVG,
                        &.IMXE,
                        &.SODX {
                            width: 90%;
                        }

                        &.FEDX,
                        &.TNTE {
                            width: 80%;
                        }

                        &.DHLE,
                        &.DHLF {
                            width: 100%;
                        }

                        &.SOGP,
                        &.UPSE {
                            width: 50%;
                        }
                    }
                }
            }

            .prices {
                @include flex(row, flex-start, flex-start);
                gap: 40px;

                @include respond-to('t-992') {
                    flex-direction: column;
                    gap: 16px;
                }

                .price {
                    .price-item {
                        @include flex(row, center, center);
                        position: relative;
                        background-color: rgba(12, 57, 100, 0.7);
                        border-radius: 36px;
                        display: block;
                        padding: 2px 32px 2px 0;
                        width: fit-content;

                        img {
                            position: absolute;
                            top: 0;
                            width: 32px;
                            height: 32px;
                        }

                        span {
                            padding-left: 40px;

                            b {
                                color: #8abffc;
                            }
                        }
                    }
                }
            }

            .padding-funnel {
                padding: 0 40px 40px;

                @include respond-to('m-768') {
                    padding: 0;
                }

                .bdrs {
                    border-radius: $bdrs-normal;
                    background-color: #ffffff;

                    @include respond-to('m-768') {
                        border-radius: initial;
                    }

                    &.mdc-elevation--z1 {
                        @include respond-to('m-768') {
                            box-shadow: none;
                        }
                    }

                    &:has(.oops) {
                        background-color: transparent;
                        box-shadow: none;
                    }
                }
            }

            .padding-cta-header {
                padding: 0 40px 40px;

                .cta {
                    margin: 0;
                }
            }
        }
    }

    .why {
        .top {
            @include flex(row, flex-start, flex-start);
            gap: 80px;

            @include respond-to('t-992') {
                gap: 40px;
            }

            @include respond-to('m-768') {
                display: block;
            }

            .text {
                flex: 2 1 0;

                @include respond-to('t-992') {
                    flex: 3 1 0;
                }
            }

            .img {
                flex: 1 1 0;

                @include respond-to('t-992') {
                    flex: 2 1 0;
                }

                @include respond-to('m-768') {
                    display: none;
                }

                img {
                    &.chrp,
                    &.dlvg,
                    &.dhle {
                        width: 80%;

                        @include respond-to('t-992') {
                            width: 100%;
                        }
                    }

                    &.chrp-2shopdirect,
                    &.chrp-shop2shop {
                        width: 50%;

                        @include respond-to('t-992') {
                            width: 100%;
                        }
                    }

                    &.copr,
                    &.imxe,
                    &.sogp {
                        width: 70%;

                        @include respond-to('d-1024') {
                            width: 100%;
                        }
                    }

                    &.pofr {
                        width: 60%;

                        @include respond-to('d-1024') {
                            width: 100%;
                        }
                    }

                    &.dhlf,
                    &.monr,
                    &.sodx,
                    &.upse {
                        width: 70%;

                        @include respond-to('d-1024') {
                            width: 100%;
                        }

                        @include respond-to('t-992') {
                            width: 80%;
                        }
                    }
                }
            }
        }

        .bottom {
            .label-icon {
                @include flex(row, center, flex-start);
                gap: 8px;

                img {
                    width: 40px;
                    height: auto;
                }

                & + p {
                    padding-left: 48px;

                    @include respond-to('m-768') {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .boxtal {
        background-color: #0a2540;
        color: #f4f3f7;

        .arguments {
            @include flex(row, stretch, flex-start);
            gap: 40px;

            @include respond-to('t-992') {
                flex-direction: column;
                gap: 40px;
            }

            .argument {
                flex: 1 1 0;
                @include flex(column, flex-start, space-between);

                .img-text {
                    .img {
                        @include flex(row, flex-start, flex-start);
                        margin-bottom: 0;

                        img {
                            height: 80px;
                            width: auto;
                        }
                    }
                }

                .cta {
                    margin-bottom: 0;
                }
            }
        }
    }

    .section-offers {
        img {
            width: 40px;
            height: auto;
        }

        h3 {
            @include flex(row, center, flex-start);
            gap: 8px;
        }

        .offer-list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 40px;

            @include respond-to('m-768') {
                display: block;
            }
        }

        li {
            list-style-type: none;

            &.chip {
                list-style-type: disc;
            }
        }
    }

    .section-ecommerce {
        background-color: #0a2540;
        color: #f4f3f7;

        .container-ecommerce {
            @include flex(row, flex-end, flex-start);
            gap: 40px;

            .text {
                flex: 3 1 0;

                @include respond-to('d-1024') {
                    flex: 1 1 0;
                }

                .illustration-mobile {
                    display: none;

                    @include respond-to('t-992') {
                        display: block;

                        img {
                            height: 160px;
                            width: auto;
                        }
                    }
                }

                .cta {
                    margin: 0;
                }
            }

            .illustration {
                flex: 2 1 0;

                @include respond-to('d-1024') {
                    flex: 1 1 0;
                }

                @include respond-to('t-992') {
                    display: none;
                }
            }
        }
    }


    .section-seo {
        background-color: #dff0fd;

        h2 ~ h2 {
            margin-top: 3rem;
        }
    }

    .section-partners {
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-gap: 40px;

            @include respond-to('t-992') {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }

            @include respond-to('m-768') {
                grid-template-columns: 1fr 1fr;
            }

            li {
                padding-left: 0;

                @include respond-to('m-768') {
                    @include flex(row, center, center);
                }

                a {
                    @include flex(row, center, center);
                    width: 64px;
                    height: 64px;
                    line-height: 64px;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: auto;

                        &.fedx,
                        &.tnte,
                        &.sodx,
                        &.imxe {
                            width: 80%;
                        }

                        &.sogp,
                        &.upse,
                        &.monr {
                            width: 55%;
                        }

                        &.chrp,
                        &.pofr {
                            width: 57%;
                        }

                        &.upsa {
                            width: 59%;
                        }

                        &.copr {
                            width: 60%;
                        }
                    }
                }
            }
        }
    }
}