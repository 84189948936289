.ecommerce-home-page {
    background: url('images/background/accueil/ecommerce-home.jpg');
    background-size: cover;

    .page-content {
        a {
            text-decoration: none;
        }

        .section-1 {
            padding: 40px 40px 40px 40px;
            color: #ffffff;
            overflow: hidden;

            .container {
                @include flex(column);
                position: relative;

                .circle {
                    position: absolute;
                    width: 1200px;
                    height: 1000px;
                    top: -50px;
                    right: -560px;
                    background-color: rgba(195, 22, 50, 0.8);
                    border-radius: 50%;

                    @include respond-to('m-768') {
                        background-color: #0d3261;
                    }
                }


                h1, p {
                    position: relative;
                }

                .cloud-container {
                    padding-bottom: 40px;

                    .cloud {
                        position: absolute;
                        height: 54px;
                        width: 960px;
                        background-color: rgba(23, 55, 95, 0.8);
                        border-radius: 40px;
                        left: -40px;
                        top: -13px;

                        @include respond-to('d-1024') {
                            width: 820px;
                        }

                        @include respond-to('t-992') {
                            width: 640px;
                        }

                        @include respond-to('m-768') {
                            background-color: #091939;
                        }

                        @media screen and (max-width: 430px) {
                            height: 76px;
                        }
                    }

                    .text {
                        position: relative;
                    }
                }
            }
        }

        .section-2 {
            padding: 40px;
            background-color: #ffffff;

            h2 {
                margin-top: 0;
                text-align: center;
            }

            p{
                text-align: center;
            }

            .flex-grid-container {
                padding-top: 16px;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-column-gap: 40px;
                grid-row-gap: 16px;

                @include respond-to('t-992') {
                    grid-template-columns: repeat(4, 1fr);
                }

                @include respond-to('m-768') {
                    grid-template-columns: repeat(3, 1fr);
                }

                @media screen and (max-width: 550px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                p {
                    margin: 0;

                    a {
                        text-decoration: none;
                        @include flex(column, center, center);
                        gap: 8px;

                        .img {
                            width: 72px;
                            height: 72px;
                            border-radius: 50%;
                            overflow: hidden;

                            img {
                                width: 72px;
                                height: auto;
                                border: none;
                            }
                        }
                    }
                }
            }

            .button {
                margin-bottom: 0;
                padding-top: 24px;
            }
        }

        .section-3, .section-5 {
            padding: 40px;
            color: #ffffff;
            overflow: hidden;

            .container {
                position: relative;
                @include flex(row-reverse, flex-start, flex-start);
                gap: 80px;

                @include respond-to('m-768') {
                    flex-direction: column;
                    gap: 0;
                }

                .left,
                .right {
                    position: relative;
                    z-index: 1;
                }

                .left {
                    flex: 2 0 66%;
                    @include flex(column, flex-end, flex-end);

                    @include respond-to('t-992') {
                        flex: 2 0 50%;
                    }

                    @include respond-to('m-768') {
                        flex: initial;
                    }

                    h2 {
                        margin-top: 0;
                        text-align: right;

                        @include respond-to('m-768') {
                            text-align: left;
                        }
                    }

                    .button {
                        @include respond-to('m-768') {
                            display: none;
                        }
                    }
                }

                .button-mobile {
                    display: none;

                    @include respond-to('m-768') {
                        display: block;
                    }
                }
            }
        }

        .section-3 {
            .circle1,
            .circle2,
            .circle3 {
                position: absolute;
                top: -250px;
                border-radius: 50%;
            }

            .circle1 {
                left: -140px;
                background-color: rgba(195, 22, 50, 0.5);
                width: 600px;
                height: 600px;
            }

            .circle2 {
                left: -120px;
                background: rgba(76, 54, 90, 0.7);
                background: rgb(39, 48, 85, 0.4);
                width: 550px;
                height: 550px;
            }

            .circle3 {
                left: -100px;
                background-color: rgba(23, 55, 95, 0.8);
                width: 500px;
                height: 500px;
            }
        }

        .section-5 {
            .circle1 {
                position: absolute;
                top: -500px;
                right: -100px;
                border-radius: 50%;
                background: rgba(255, 0, 0, 0.43);
                width: 800px;
                height: 800px;

                @include respond-to('d-1310') {
                    right: -160px;
                }

                @include respond-to('t-992') {
                    right: -320px;
                }

                @include respond-to('m-768') {
                    width: 500px;
                    height: 500px;
                    top: -180px;
                    right: -80px;
                }
            }

            .circle2 {
                position: absolute;
                top: -560px;
                left: -360px;
                border-radius: 50%;
                background: rgba(23, 55, 95, 0.8);

                width: 1000px;
                height: 1000px;

                @include respond-to('m-768') {
                    width: 500px;
                    height: 500px;
                    top: -180px;
                    left: -80px;
                }
            }
        }

        .section-4 {
            background-color: #ffffff;
            padding: 40px;

            h2 {
                margin-top: 0;
                position: relative;

                .box1, .box2 {
                    position: absolute;
                    top: 8px;
                    @include flex(row, center, center);
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    color: #ffffff;

                    @include respond-to('t-992') {
                        display: none;
                    }
                }

                .box1 {
                    background-color: #173a64;
                    color: #5ea2f3;
                    left: 740px;
                }

                .box2 {
                    background-color: #6f0a18;
                    color: #fc7171;
                    left: 788px;
                }
            }

            .button {
                padding-top: 24px;
            }
        }
    }
}
