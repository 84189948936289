@mixin respond-to($breakpoint) {
    $raw-query: map-get($breakpoints, $breakpoint);

    @if $raw-query {
        $query: if(
                type-of($raw-query) == 'string',
                unquote($raw-query),
                inspect($raw-query)
        );

        @media #{$query} {
            @content;
        }
    } @else {
        @error 'No value found for `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin flex($direction: row, $align: null, $justify: null) {
    display: flex;

    flex-direction: $direction;
    @if $align {
        align-items: $align;
    }
    @if $justify {
        justify-content: $justify;
    }
}

@mixin flex-grow($grow: 1) {
    flex-grow: $grow;
    flex-basis: 0;
    @include safari-only() {
        flex-basis:auto;
    }
}

@mixin flex-row-to-column($breakpoint, $align: null, $justify: null) {
    @include flex(row, $align, $justify);

    @include respond-to($breakpoint) {
        flex-direction: column;
    }
}

@mixin hide-for($breakpoint) {
    @include respond-to($breakpoint) {
        display: none;
        visibility: hidden;
    }
}

@mixin show-for($breakpoint, $display: initial) {
    display: none;
    visibility: hidden;
    @include respond-to($breakpoint) {
        display: $display;
        visibility: visible;
    }
}
