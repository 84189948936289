.page-error {
    color: #ffffff;

    header {
        position: relative;
        z-index: 2;
        height: 56px;
        @include flex(row, center, flex-start);

        p {
            margin: 0;
            padding: 0 40px;

            @include respond-to('m-768') {
                padding: 0 32px;
            }

            img {
                width: 160px;
                height: auto;
            }
        }
    }

    .text-container {
        @include flex(column, center, center);
        min-height: calc(100vh - 56px);
        background: url('images/background/error.jpg') bottom center no-repeat;
        background-size: cover;

        > * {
            padding: 0 40px;
            text-align: center;
        }

        h2 {
            a {
                color: #ffffff;
                text-decoration-color: rgba(255, 255, 255, 0.5);
            }
        }

        .button-container {
            a {
                text-decoration: none;
            }
        }
    }
}
