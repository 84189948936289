.theme {
    padding: 40px;

    h1 {
        &.example {
            margin-top: 3rem;
        }
    }

    .limited-paragraph-width-small {
        max-width: 440px;
    }

    .limited-paragraph-width {
        max-width: 560px;
    }

    .spacer {
        width: 120px;
        height: 4px;
        border-radius: $bdrs-normal;
        background-color: $color-main;
    }

    &.typography,
    &.icons,
    &.buttons,
    &.colors{
        h1 {
            //margin-top: 3rem;

            a {
                text-decoration: none;
            }
        }
    }

    &.icons {
        .size48 {
            font-size: 48px;
            width: 48px;
            height: 48px;
        }

        .color {
            color: #fa82e6;
        }

        .weight {
            --md-icon-weight: 600;
        }
    }

    &.buttons {
        .native {
            --md-elevated-button-label-text-font: 500 0.875rem/2.25rem Roboto;
            --md-outlined-button-label-text-font: 500 0.875rem/2.25rem Roboto;
            --md-filled-button-label-text-font: 500 0.875rem/2.25rem Roboto;
            --md-text-button-label-text-font: 500 0.875rem/2.25rem Roboto;
            --md-filled-tonal-button-label-text-font: 500 0.875rem/2.25rem Roboto;

            --md-elevated-button-container-height: 40px;
            --md-outlined-button-container-height: 40px;
            --md-filled-button-container-height: 40px;
            --md-text-button-container-height: 40px;
            --md-filled-tonal-button-container-height: 40px;

            --md-elevated-button-container-shape: 9999px;
            --md-outlined-button-container-shape: 9999px;
            --md-filled-button-container-shape: 9999px;
            --md-text-button-container-shape: 9999px;
            --md-filled-tonal-button-container-shape: 9999px;
        }

        .text {
            --md-elevated-button-container-height: 40px;
            --md-outlined-button-container-height: 40px;
            --md-filled-button-container-height: 40px;
            --md-text-button-container-height: 40px;
            --md-filled-tonal-button-container-height: 40px;

            --md-elevated-button-container-shape: 9999px;
            --md-outlined-button-container-shape: 9999px;
            --md-filled-button-container-shape: 9999px;
            --md-text-button-container-shape: 9999px;
            --md-filled-tonal-button-container-shape: 9999px;
        }

        .height {
            --md-elevated-button-container-shape: 9999px;
            --md-outlined-button-container-shape: 9999px;
            --md-filled-button-container-shape: 9999px;
            --md-text-button-container-shape: 9999px;
            --md-filled-tonal-button-container-shape: 9999px;
        }
    }
}
