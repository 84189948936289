:root {
  --md-source: #2f5f9a;
  /* primary */
  --md-ref-palette-primary0: #000000;
  --md-ref-palette-primary4: #000e23;
  --md-ref-palette-primary5: #001128;
  --md-ref-palette-primary6: #00142c;
  --md-ref-palette-primary10: #001c3a;
  --md-ref-palette-primary12: #002041;
  --md-ref-palette-primary17: #002a53;
  --md-ref-palette-primary20: #00315e;
  --md-ref-palette-primary22: #003566;
  --md-ref-palette-primary24: #003a6e;
  --md-ref-palette-primary25: #003c71;
  --md-ref-palette-primary30: #004785;
  --md-ref-palette-primary35: #035399;
  --md-ref-palette-primary40: #1e5fa6;
  --md-ref-palette-primary50: #3f79c0;
  --md-ref-palette-primary60: #5b93dc;
  --md-ref-palette-primary70: #77adf9;
  --md-ref-palette-primary80: #a5c8ff;
  --md-ref-palette-primary87: #c6dbff;
  --md-ref-palette-primary90: #d4e3ff;
  --md-ref-palette-primary92: #dde9ff;
  --md-ref-palette-primary94: #e6eeff;
  --md-ref-palette-primary95: #ebf1ff;
  --md-ref-palette-primary96: #f0f3ff;
  --md-ref-palette-primary98: #f9f9ff;
  --md-ref-palette-primary99: #fdfcff;
  --md-ref-palette-primary100: #ffffff;
  /* secondary */
  --md-ref-palette-secondary0: #000000;
  --md-ref-palette-secondary4: #040e1d;
  --md-ref-palette-secondary5: #061120;
  --md-ref-palette-secondary6: #091423;
  --md-ref-palette-secondary10: #111c2b;
  --md-ref-palette-secondary12: #16202f;
  --md-ref-palette-secondary17: #202a3a;
  --md-ref-palette-secondary20: #263141;
  --md-ref-palette-secondary22: #2b3545;
  --md-ref-palette-secondary24: #2f3a4a;
  --md-ref-palette-secondary25: #323c4c;
  --md-ref-palette-secondary30: #3d4758;
  --md-ref-palette-secondary35: #485364;
  --md-ref-palette-secondary40: #545f71;
  --md-ref-palette-secondary50: #6d788a;
  --md-ref-palette-secondary60: #8791a4;
  --md-ref-palette-secondary70: #a1acc0;
  --md-ref-palette-secondary80: #bcc7dc;
  --md-ref-palette-secondary87: #d0daf0;
  --md-ref-palette-secondary90: #d8e3f8;
  --md-ref-palette-secondary92: #dee9fe;
  --md-ref-palette-secondary94: #e6eeff;
  --md-ref-palette-secondary95: #ebf1ff;
  --md-ref-palette-secondary96: #f0f3ff;
  --md-ref-palette-secondary98: #f9f9ff;
  --md-ref-palette-secondary99: #fdfcff;
  --md-ref-palette-secondary100: #ffffff;
  /* tertiary */
  --md-ref-palette-tertiary0: #000000;
  --md-ref-palette-tertiary4: #180622;
  --md-ref-palette-tertiary5: #1b0924;
  --md-ref-palette-tertiary6: #1e0b27;
  --md-ref-palette-tertiary10: #271430;
  --md-ref-palette-tertiary12: #2b1834;
  --md-ref-palette-tertiary17: #36223f;
  --md-ref-palette-tertiary20: #3d2946;
  --md-ref-palette-tertiary22: #422d4b;
  --md-ref-palette-tertiary24: #46314f;
  --md-ref-palette-tertiary25: #493352;
  --md-ref-palette-tertiary30: #553f5d;
  --md-ref-palette-tertiary35: #614a6a;
  --md-ref-palette-tertiary40: #6d5676;
  --md-ref-palette-tertiary50: #876e90;
  --md-ref-palette-tertiary60: #a288ab;
  --md-ref-palette-tertiary70: #bea2c6;
  --md-ref-palette-tertiary80: #dabde2;
  --md-ref-palette-tertiary87: #eed0f6;
  --md-ref-palette-tertiary90: #f7d8ff;
  --md-ref-palette-tertiary92: #f9e0ff;
  --md-ref-palette-tertiary94: #fce7ff;
  --md-ref-palette-tertiary95: #fdebff;
  --md-ref-palette-tertiary96: #ffefff;
  --md-ref-palette-tertiary98: #fff7fc;
  --md-ref-palette-tertiary99: #fffbff;
  --md-ref-palette-tertiary100: #ffffff;
  /* neutral */
  --md-ref-palette-neutral0: #000000;
  --md-ref-palette-neutral4: #0d0e11;
  --md-ref-palette-neutral5: #101114;
  --md-ref-palette-neutral6: #121316;
  --md-ref-palette-neutral10: #1a1c1e;
  --md-ref-palette-neutral12: #1e2022;
  --md-ref-palette-neutral17: #292a2d;
  --md-ref-palette-neutral20: #2f3033;
  --md-ref-palette-neutral22: #343538;
  --md-ref-palette-neutral24: #38393c;
  --md-ref-palette-neutral25: #3a3b3e;
  --md-ref-palette-neutral30: #46474a;
  --md-ref-palette-neutral35: #515256;
  --md-ref-palette-neutral40: #5d5e62;
  --md-ref-palette-neutral50: #76777a;
  --md-ref-palette-neutral60: #909094;
  --md-ref-palette-neutral70: #ababae;
  --md-ref-palette-neutral80: #c7c6ca;
  --md-ref-palette-neutral87: #dad9dd;
  --md-ref-palette-neutral90: #e3e2e6;
  --md-ref-palette-neutral92: #e9e8eb;
  --md-ref-palette-neutral94: #eeedf1;
  --md-ref-palette-neutral95: #f1f0f4;
  --md-ref-palette-neutral96: #f4f3f7;
  --md-ref-palette-neutral98: #faf9fd;
  --md-ref-palette-neutral99: #fdfcff;
  --md-ref-palette-neutral100: #ffffff;
  /* neutral-variant */
  --md-ref-palette-neutral-variant0: #000000;
  --md-ref-palette-neutral-variant4: #0a0e15;
  --md-ref-palette-neutral-variant5: #0d1117;
  --md-ref-palette-neutral-variant6: #10141a;
  --md-ref-palette-neutral-variant10: #181c22;
  --md-ref-palette-neutral-variant12: #1c2026;
  --md-ref-palette-neutral-variant17: #262a31;
  --md-ref-palette-neutral-variant20: #2d3138;
  --md-ref-palette-neutral-variant22: #31353c;
  --md-ref-palette-neutral-variant24: #363940;
  --md-ref-palette-neutral-variant25: #383c43;
  --md-ref-palette-neutral-variant30: #43474e;
  --md-ref-palette-neutral-variant35: #4f525a;
  --md-ref-palette-neutral-variant40: #5b5e66;
  --md-ref-palette-neutral-variant50: #74777f;
  --md-ref-palette-neutral-variant60: #8d9199;
  --md-ref-palette-neutral-variant70: #a8abb4;
  --md-ref-palette-neutral-variant80: #c3c6cf;
  --md-ref-palette-neutral-variant87: #d7dae3;
  --md-ref-palette-neutral-variant90: #e0e2ec;
  --md-ref-palette-neutral-variant92: #e5e8f1;
  --md-ref-palette-neutral-variant94: #ebeef7;
  --md-ref-palette-neutral-variant95: #eef0fa;
  --md-ref-palette-neutral-variant96: #f1f3fd;
  --md-ref-palette-neutral-variant98: #f9f9ff;
  --md-ref-palette-neutral-variant99: #fdfcff;
  --md-ref-palette-neutral-variant100: #ffffff;
  /* error */
  --md-ref-palette-error0: #000000;
  --md-ref-palette-error4: #280001;
  --md-ref-palette-error5: #2d0001;
  --md-ref-palette-error6: #310001;
  --md-ref-palette-error10: #410002;
  --md-ref-palette-error12: #490002;
  --md-ref-palette-error17: #5c0004;
  --md-ref-palette-error20: #690005;
  --md-ref-palette-error22: #710005;
  --md-ref-palette-error24: #790006;
  --md-ref-palette-error25: #7e0007;
  --md-ref-palette-error30: #93000a;
  --md-ref-palette-error35: #a80710;
  --md-ref-palette-error40: #ba1a1a;
  --md-ref-palette-error50: #de3730;
  --md-ref-palette-error60: #ff5449;
  --md-ref-palette-error70: #ff897d;
  --md-ref-palette-error80: #ffb4ab;
  --md-ref-palette-error87: #ffcfc9;
  --md-ref-palette-error90: #ffdad6;
  --md-ref-palette-error92: #ffe2de;
  --md-ref-palette-error94: #ffe9e6;
  --md-ref-palette-error95: #ffedea;
  --md-ref-palette-error96: #fff0ee;
  --md-ref-palette-error98: #fff8f7;
  --md-ref-palette-error99: #fffbff;
  --md-ref-palette-error100: #ffffff;
  /* light */
  --md-sys-color-primary-light: #1e5fa6;
  --md-sys-color-on-primary-light: #ffffff;
  --md-sys-color-primary-container-light: #d4e3ff;
  --md-sys-color-on-primary-container-light: #001c3a;
  --md-sys-color-primary-fixed-light: #d4e3ff;
  --md-sys-color-on-primary-fixed-light: #001c3a;
  --md-sys-color-primary-fixed-dim-light: #a5c8ff;
  --md-sys-color-on-primary-fixed-variant-light: #004785;
  --md-sys-color-secondary-light: #545f71;
  --md-sys-color-on-secondary-light: #ffffff;
  --md-sys-color-secondary-container-light: #d8e3f8;
  --md-sys-color-on-secondary-container-light: #111c2b;
  --md-sys-color-secondary-fixed-light: #d8e3f8;
  --md-sys-color-on-secondary-fixed-light: #111c2b;
  --md-sys-color-secondary-fixed-dim-light: #bcc7dc;
  --md-sys-color-on-secondary-fixed-variant-light: #3d4758;
  --md-sys-color-tertiary-light: #6d5676;
  --md-sys-color-on-tertiary-light: #ffffff;
  --md-sys-color-tertiary-container-light: #f7d8ff;
  --md-sys-color-on-tertiary-container-light: #271430;
  --md-sys-color-tertiary-fixed-light: #f7d8ff;
  --md-sys-color-on-tertiary-fixed-light: #271430;
  --md-sys-color-tertiary-fixed-dim-light: #dabde2;
  --md-sys-color-on-tertiary-fixed-variant-light: #553f5d;
  --md-sys-color-error-light: #ba1a1a;
  --md-sys-color-error-container-light: #ffdad6;
  --md-sys-color-on-error-light: #ffffff;
  --md-sys-color-on-error-container-light: #410002;
  --md-sys-color-background-light: #fdfcff;
  --md-sys-color-on-background-light: #1a1c1e;
  --md-sys-color-outline-light: #74777f;
  --md-sys-color-inverse-on-surface-light: #f1f0f4;
  --md-sys-color-inverse-surface-light: #2f3033;
  --md-sys-color-inverse-primary-light: #a5c8ff;
  --md-sys-color-shadow-light: #000000;
  --md-sys-color-surface-tint-light: #1e5fa6;
  --md-sys-color-outline-variant-light: #c3c6cf;
  --md-sys-color-scrim-light: #000000;
  --md-sys-color-surface-light: #faf9fd;
  --md-sys-color-on-surface-light: #1a1c1e;
  --md-sys-color-surface-variant-light: #e0e2ec;
  --md-sys-color-on-surface-variant-light: #43474e;
  --md-sys-color-surface-container-highest-light: #e3e2e6;
  --md-sys-color-surface-container-high-light: #e9e8eb;
  --md-sys-color-surface-container-light: #eeedf1;
  --md-sys-color-surface-container-low-light: #f4f3f7;
  --md-sys-color-surface-container-lowest-light: #ffffff;
  --md-sys-color-surface-dim-light: #dad9dd;
  --md-sys-color-surface-bright-light: #faf9fd;
  /* dark */
  --md-sys-color-primary-dark: #a5c8ff;
  --md-sys-color-on-primary-dark: #00315e;
  --md-sys-color-primary-container-dark: #004785;
  --md-sys-color-on-primary-container-dark: #d4e3ff;
  --md-sys-color-primary-fixed-dark: #d4e3ff;
  --md-sys-color-on-primary-fixed-dark: #001c3a;
  --md-sys-color-primary-fixed-dim-dark: #a5c8ff;
  --md-sys-color-on-primary-fixed-variant-dark: #004785;
  --md-sys-color-secondary-dark: #bcc7dc;
  --md-sys-color-on-secondary-dark: #263141;
  --md-sys-color-secondary-container-dark: #3d4758;
  --md-sys-color-on-secondary-container-dark: #d8e3f8;
  --md-sys-color-secondary-fixed-dark: #d8e3f8;
  --md-sys-color-on-secondary-fixed-dark: #111c2b;
  --md-sys-color-secondary-fixed-dim-dark: #bcc7dc;
  --md-sys-color-on-secondary-fixed-variant-dark: #3d4758;
  --md-sys-color-tertiary-dark: #dabde2;
  --md-sys-color-on-tertiary-dark: #3d2946;
  --md-sys-color-tertiary-container-dark: #553f5d;
  --md-sys-color-on-tertiary-container-dark: #f7d8ff;
  --md-sys-color-tertiary-fixed-dark: #f7d8ff;
  --md-sys-color-on-tertiary-fixed-dark: #271430;
  --md-sys-color-tertiary-fixed-dim-dark: #dabde2;
  --md-sys-color-on-tertiary-fixed-variant-dark: #553f5d;
  --md-sys-color-error-dark: #ffb4ab;
  --md-sys-color-error-container-dark: #93000a;
  --md-sys-color-on-error-dark: #690005;
  --md-sys-color-on-error-container-dark: #ffdad6;
  --md-sys-color-background-dark: #1a1c1e;
  --md-sys-color-on-background-dark: #e3e2e6;
  --md-sys-color-outline-dark: #8d9199;
  --md-sys-color-inverse-on-surface-dark: #1a1c1e;
  --md-sys-color-inverse-surface-dark: #e3e2e6;
  --md-sys-color-inverse-primary-dark: #1e5fa6;
  --md-sys-color-shadow-dark: #000000;
  --md-sys-color-surface-tint-dark: #a5c8ff;
  --md-sys-color-outline-variant-dark: #43474e;
  --md-sys-color-scrim-dark: #000000;
  --md-sys-color-surface-dark: #121316;
  --md-sys-color-on-surface-dark: #c7c6ca;
  --md-sys-color-surface-variant-dark: #43474e;
  --md-sys-color-on-surface-variant-dark: #c3c6cf;
  --md-sys-color-surface-container-highest-dark: #343538;
  --md-sys-color-surface-container-high-dark: #292a2d;
  --md-sys-color-surface-container-dark: #1e2022;
  --md-sys-color-surface-container-low-dark: #1a1c1e;
  --md-sys-color-surface-container-lowest-dark: #0d0e11;
  --md-sys-color-surface-dim-dark: #121316;
  --md-sys-color-surface-bright-dark: #38393c;
  /* display - large */
  --md-sys-typescale-display-large-font-family-name: Fira Sans;
  --md-sys-typescale-display-large-font-family-style: Regular;
  --md-sys-typescale-display-large-font-weight: 400px;
  --md-sys-typescale-display-large-font-size: 57px;
  --md-sys-typescale-display-large-line-height: 64px;
  --md-sys-typescale-display-large-letter-spacing: -0.25px;
  /* display - medium */
  --md-sys-typescale-display-medium-font-family-name: Fira Sans;
  --md-sys-typescale-display-medium-font-family-style: Regular;
  --md-sys-typescale-display-medium-font-weight: 400px;
  --md-sys-typescale-display-medium-font-size: 45px;
  --md-sys-typescale-display-medium-line-height: 52px;
  --md-sys-typescale-display-medium-letter-spacing: 0px;
  /* display - small */
  --md-sys-typescale-display-small-font-family-name: Fira Sans;
  --md-sys-typescale-display-small-font-family-style: Regular;
  --md-sys-typescale-display-small-font-weight: 400px;
  --md-sys-typescale-display-small-font-size: 36px;
  --md-sys-typescale-display-small-line-height: 44px;
  --md-sys-typescale-display-small-letter-spacing: 0px;
  /* headline - large */
  --md-sys-typescale-headline-large-font-family-name: Fira Sans;
  --md-sys-typescale-headline-large-font-family-style: Regular;
  --md-sys-typescale-headline-large-font-weight: 400px;
  --md-sys-typescale-headline-large-font-size: 32px;
  --md-sys-typescale-headline-large-line-height: 40px;
  --md-sys-typescale-headline-large-letter-spacing: 0px;
  /* headline - medium */
  --md-sys-typescale-headline-medium-font-family-name: Fira Sans;
  --md-sys-typescale-headline-medium-font-family-style: Regular;
  --md-sys-typescale-headline-medium-font-weight: 400px;
  --md-sys-typescale-headline-medium-font-size: 28px;
  --md-sys-typescale-headline-medium-line-height: 36px;
  --md-sys-typescale-headline-medium-letter-spacing: 0px;
  /* headline - small */
  --md-sys-typescale-headline-small-font-family-name: Fira Sans;
  --md-sys-typescale-headline-small-font-family-style: Regular;
  --md-sys-typescale-headline-small-font-weight: 400px;
  --md-sys-typescale-headline-small-font-size: 24px;
  --md-sys-typescale-headline-small-line-height: 32px;
  --md-sys-typescale-headline-small-letter-spacing: 0px;
  /* body - large */
  --md-sys-typescale-body-large-font-family-name: Fira Sans;
  --md-sys-typescale-body-large-font-family-style: Regular;
  --md-sys-typescale-body-large-font-weight: 400px;
  --md-sys-typescale-body-large-font-size: 16px;
  --md-sys-typescale-body-large-line-height: 24px;
  --md-sys-typescale-body-large-letter-spacing: 0.50px;
  /* body - medium */
  --md-sys-typescale-body-medium-font-family-name: Fira Sans;
  --md-sys-typescale-body-medium-font-family-style: Regular;
  --md-sys-typescale-body-medium-font-weight: 400px;
  --md-sys-typescale-body-medium-font-size: 14px;
  --md-sys-typescale-body-medium-line-height: 20px;
  --md-sys-typescale-body-medium-letter-spacing: 0.25px;
  /* body - small */
  --md-sys-typescale-body-small-font-family-name: Fira Sans;
  --md-sys-typescale-body-small-font-family-style: Regular;
  --md-sys-typescale-body-small-font-weight: 400px;
  --md-sys-typescale-body-small-font-size: 12px;
  --md-sys-typescale-body-small-line-height: 16px;
  --md-sys-typescale-body-small-letter-spacing: 0.40px;
  /* label - large */
  --md-sys-typescale-label-large-font-family-name: Fira Sans;
  --md-sys-typescale-label-large-font-family-style: Medium;
  --md-sys-typescale-label-large-font-weight: 500px;
  --md-sys-typescale-label-large-font-size: 14px;
  --md-sys-typescale-label-large-line-height: 20px;
  --md-sys-typescale-label-large-letter-spacing: 0.10px;
  /* label - medium */
  --md-sys-typescale-label-medium-font-family-name: Fira Sans;
  --md-sys-typescale-label-medium-font-family-style: Medium;
  --md-sys-typescale-label-medium-font-weight: 500px;
  --md-sys-typescale-label-medium-font-size: 12px;
  --md-sys-typescale-label-medium-line-height: 16px;
  --md-sys-typescale-label-medium-letter-spacing: 0.50px;
  /* label - small */
  --md-sys-typescale-label-small-font-family-name: Fira Sans;
  --md-sys-typescale-label-small-font-family-style: Medium;
  --md-sys-typescale-label-small-font-weight: 500px;
  --md-sys-typescale-label-small-font-size: 11px;
  --md-sys-typescale-label-small-line-height: 16px;
  --md-sys-typescale-label-small-letter-spacing: 0.50px;
  /* title - large */
  --md-sys-typescale-title-large-font-family-name: Fira Sans;
  --md-sys-typescale-title-large-font-family-style: Regular;
  --md-sys-typescale-title-large-font-weight: 400px;
  --md-sys-typescale-title-large-font-size: 22px;
  --md-sys-typescale-title-large-line-height: 28px;
  --md-sys-typescale-title-large-letter-spacing: 0px;
  /* title - medium */
  --md-sys-typescale-title-medium-font-family-name: Fira Sans;
  --md-sys-typescale-title-medium-font-family-style: Medium;
  --md-sys-typescale-title-medium-font-weight: 500px;
  --md-sys-typescale-title-medium-font-size: 16px;
  --md-sys-typescale-title-medium-line-height: 24px;
  --md-sys-typescale-title-medium-letter-spacing: 0.15px;
  /* title - small */
  --md-sys-typescale-title-small-font-family-name: Fira Sans;
  --md-sys-typescale-title-small-font-family-style: Medium;
  --md-sys-typescale-title-small-font-weight: 500px;
  --md-sys-typescale-title-small-font-size: 14px;
  --md-sys-typescale-title-small-line-height: 20px;
  --md-sys-typescale-title-small-letter-spacing: 0.10px;
}
