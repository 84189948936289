.unified-tracking {
    background-image: url('images/background/unified-tracking.jpg');
    background-size: 100%;
    background-color: #020A17;
    background-repeat: no-repeat;

    .page-content {
        .top {
            h1, p {
                color: #ffffff;
            }

            .intro {
                padding: 40px;

                .create-account {
                    padding-top: 40px;

                    p {
                        margin: 0;
                    }

                    .img-container {
                        padding-bottom: 40px;

                        img {
                            max-width: 440px;
                        }
                    }

                    .button {
                        margin: 0;
                        padding-top: 40px;

                        a {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .bottom {
            background-color: #ffffff;
            padding: 40px;

            h2 {
                margin-top: 0;
                text-align: right;

                @include respond-to('t-992') {
                    text-align: left;
                }
            }

            .flex-container {
                gap: 40px;
                @include flex-row-to-column('t-992', flex-start, space-between);

                .left {
                    flex: 2 0 440px;

                    @include respond-to('t-992') {
                        flex: initial;
                        width: 100%;
                        max-width: 440px;
                        order: 2;
                    }

                    p {
                        margin: 0;
                    }
                }

                .right {
                    text-align: right;

                    @include respond-to('t-992') {
                        text-align: left;
                    }
                }
            }
        }
    }
}
