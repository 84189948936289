.first-km {
    .padding {
        padding: 40px;
    }

    .vertical-spacer {
        padding: 20px 0;
    }

    h2 {
        margin-top: 0;
    }

    .brush {
        background-image: url('images/illustrations/1km/line-brush.svg');
        background-size: cover;
    }

    .brush-2 {
        background-image: url('images/illustrations/1km/line-brush-2.svg');
        background-size: cover;
    }

    header {
        background-color: #0a2540;
        color: #f4f3f7;

        .header-container {
            @include flex(row, flex-end, flex-start);
            gap: 40px;

            .text,
            .illustration {
                flex: 1 1 0;
            }

            .text {
                h1 {
                    padding-bottom: 24px;
                }

                .cta {
                    margin: 0;
                }
            }

            .illustration {
                @include respond-to('t-992') {
                    display: none;
                }

                p {
                    text-align: center;
                    margin: 0;

                    img {
                        width: 80%;
                        height: auto;
                    }
                }
            }
        }
    }

    .optimize {
        .optimize-container {
            @include flex(row, flex-end, flex-start);
            flex-direction: row-reverse;
            gap: 40px;

            @include respond-to('m-768') {
                flex-direction: column;
            }

            .text {
                flex: 2 1 0;

                @include respond-to('t-992') {
                    flex: 1 1 0;
                }

                .cta {
                    margin: 0;

                    @include respond-to('m-768') {
                        text-align: center;
                    }
                }
            }

            .illustration {
                flex: 1 1 0;

                p {
                    text-align: center;
                    margin: 0;

                    img {
                        width: 80%;
                        height: auto;

                        @include respond-to('m-768') {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

    .flexibility {
        background-color: #0a2540;
        color: #f4f3f7;

        .flexibility-container {
            @include flex(row, center, flex-start);
            gap: 40px;

            @include respond-to('t-992') {
                flex-direction: column;
            }

            .text,
            .illustration {
                flex: 1 1 0;
            }

            .text {
                .cta {
                    margin: 0;
                }
            }

            .illustration {
                p {
                    text-align: right;
                    margin: 0;

                    img {
                        width: 90%;
                        height: auto;

                        @include respond-to('t-992') {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }

    .pricing {
        .pricing-container {
            @include flex(row, center, flex-start);
            gap: 40px;
            flex-direction: row-reverse;

            @include respond-to('t-992') {
                flex-direction: column;
            }

            .text,
            .table-container {
                flex: 1 1 0;
            }

            .text {
                .cta {
                    margin: 0;
                }
            }

            .table-container {
                .table {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 16px;

                    div {
                        @include flex(row, center, center);
                    }

                    .ch-1, .ch-2 {
                        text-align: center;
                        padding: 0 8px;

                        @include respond-to('m-768') {
                            padding: 0 4px;
                        }
                    }

                    .rh-1, .c-11, .c-12, .rh-2, .c-21, .c-22 {
                        padding: 24px 8px;
                        background-color: #eff6fc;

                        @include respond-to('m-768') {
                            padding: 16px 4px;
                        }
                    }

                    .c-12, .c-22 {
                        background-color: #fcf3f3;
                    }
                }

                .limits {
                    margin-bottom: 0;
                }
            }
        }
    }

    .customer-care {
        background-color: #0a2540;
        color: #f4f3f7;

        .customer-care-container {
            @include flex(row, flex-start, flex-start);
            gap: 40px;

            .text {
                flex: 2 1 0;

                @include respond-to('t-992') {
                    flex: 1 1 0;
                }

                .cta {
                    margin: 0;
                }
            }

            .illustration {
                flex: 1 1 0;

                @include respond-to('m-768') {
                    display: none;
                }

                p {
                    text-align: right;
                    margin: 0;

                    img {
                        width: 90%;
                        height: auto;
                        opacity: 0.7;
                    }
                }
            }
        }
    }

    .how-to {
        .steps {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 40px;

            @include respond-to('d-1024') {
                grid-template-columns: repeat(2, 1fr);
            }

            @include respond-to('m-768') {
                grid-template-columns: 1fr;
            }

            .step {
                .img {
                    @include flex(row, center, flex-start);
                    overflow: hidden;
                    margin: 3rem 0 0;

                    img {
                        width: 90%;

                        @include respond-to('d-1024') {
                            width: 40%;
                        }

                        @include respond-to('m-374') {
                            width: 50%;
                        }
                    }
                }
            }
        }

        .cta {
            margin: 0;
        }
    }

    .mono-collection {
        background-color: #0a2540;
        color: #f4f3f7;

        .mono-collection-container {
            @include flex(row, flex-end, flex-start);
            gap: 40px;

            @include respond-to('t-992') {
                flex-direction: column;
            }

            .text,
            .illustration {
                flex: 1 1 0;
            }

            .text {

                .cta {
                    margin: 0;
                }
            }

            .illustration {
                p {
                    text-align: right;
                    margin: 0;

                    img {
                        width: 100%;
                        height: auto;

                        @include respond-to('t-992') {
                            width: 50%;
                        }

                        @include respond-to('m-768') {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .faq {
        .faq-list {
            details {
                overflow: hidden;
                border-top: none;

                &[open] {
                    md-icon {
                        transform: rotate(90deg);
                        transition-property: transform;
                    }
                }

                summary {
                    display: grid;
                    cursor: pointer;
                    list-style: none;

                    &::-webkit-details-marker {
                        display: none;
                    }

                    &:hover {
                        outline: none;

                        p {
                            background-color: rgba(0, 0, 0, 0.03);
                        }
                    }

                    .question {
                        padding: 24px 0;
                        margin: 0;

                        @include flex(row, center, flex-start);
                        gap: 40px;
                    }
                }
            }
        }
    }
}