.rates-home-page {
    .page-content {
        padding: 40px;
        background-image: url('images/background/accueil/rates-home.jpg');
        background-size: cover;
        background-repeat: no-repeat;

        @include respond-to('m-768') {
            padding: 40px 0;
        }

        .pseudo-card {
            padding: 40px;
            border-radius: $bdrs-normal;

            @include respond-to('m-768') {
                border-radius: initial;
            }
        }

        h1 {
            color: #ffffff;

            @include respond-to('m-768') {
                padding: 0 40px;
            }
        }

        .flex {
            gap: 40px;
            padding-top: 40px;
            @include flex-row-to-column('d-1024', stretch, flex-start);

            @include respond-to('d-1024') {
                padding-top: 0;
            }

            .left {
                flex: 1 2 60%;
                color: #ffffff;
                @include flex(column, flex-start, space-between);

                a {
                    color: #ffffff;
                    text-decoration-color: rgba(255, 255, 255, 0.5);
                }

                .headline-arguments {
                    margin: 0;
                    padding-top: 37px;
                    line-height: 1.75rem;

                    @include respond-to('d-1024') {
                        padding-top: 0;
                        margin: 1rem 0;
                    }

                    @include respond-to('m-768') {
                        padding: 0 40px;
                    }

                    a {
                        text-decoration-color: #ffffff;
                    }
                }

                .argument-list {
                    padding: 40px 0 0 0;

                    .pseudo-card {
                        background: rgba(31, 68, 113, 0.7);
                        @include flex(column);
                        gap: 32px;
                        padding: 60px 40px 40px 40px;
                        position: relative;

                        &:after {
                            position: absolute;
                            content: '';
                            display: block;
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            background-color: rgba(195, 22, 50, 0.8);
                            top: -40px;
                            left: 0;
                            right: 0;
                            margin-left: auto;
                            margin-right: auto;

                            @include respond-to('m-768') {
                                width: 60px;
                                height: 60px;
                                top: -30px;
                            }
                        }

                        p {
                            margin: 0;
                            @include flex(row, center, flex-start);

                            @include respond-to('m-768') {
                                align-items: flex-start;
                            }

                            .icon {
                                @include flex(row, center, center);

                                width: 48px;
                                height: 48px;
                                background: #0e2b4b;
                                border-radius: 50%;
                                flex-shrink: 0;
                            }

                            span.text {
                                padding-left: 24px;
                            }
                        }
                    }
                }
            }

            .right {
                flex: 1 2 40%;

                .bdrs {
                    border-radius: $bdrs-normal;
                    background-color: #ffffff;

                    &:has(.oops) {
                        width: fit-content;
                        background-color: initial;

                        @include respond-to('m-768') {
                            padding-left: 40px;
                        }
                    }

                    @include respond-to('m-768') {
                        box-shadow: initial;
                        border-radius: initial;
                    }
                }
            }
        }
    }
}
