.customer-service {
    background-image: url('images/background/customer-service.jpg');
    background-size: cover;
    background-position: left -100px;
    background-repeat: no-repeat;

    .page-content {
        .pseudo-card {
            padding: 40px;
            border-radius: $bdrs-normal;

            @include respond-to('m-768') {
                border-radius: initial;
            }
        }

        .top {

            h1, p, li {
                color: #ffffff;
            }

            .intro {
                padding: 40px 40px 0 40px;

                h1 {
                    position: relative;

                    &:after {
                        content: url('images/pictograms/chat-bubble.svg');
                        position: absolute;
                        left: 670px;
                        top: -16px;
                        width: 130px;
                        height: auto;
                        opacity: 0.8;

                        @include respond-to('t-992') {
                            left: 500px;
                            width: 100px;
                        }

                        @include respond-to('m-768') {
                            left: 350px;
                            width: 80px;
                        }

                        @media (max-width: 409px) {
                            left: 140px;
                            top: 48px;
                            width: 40px;
                        }
                    }
                }

                p + p {
                    padding-bottom: 24px;
                }
            }

            .benefits {
                padding: 0 40px 40px 40px;

                @include respond-to('m-768') {
                    padding: 0 0 0 0;
                }

                ul {
                    list-style-type: none;
                    max-width: 800px;
                    margin: 0;
                    background-color: rgba(48, 87, 129, 0.6);

                    @include respond-to('m-768') {
                        background-color: #1e3c5d;
                    }

                    li {
                        @include flex(row, flex-start, flex-start);
                        padding: 0 0 24px 0;

                        &:last-child {
                            padding: 0;
                        }

                        .icon {
                            height: 24px;
                            width: auto;
                            transform: scaleX(-1);
                        }

                        .text {
                            padding: 0 0 0 16px;
                        }
                    }
                }
            }
        }

        .bottom {
            background-color: #ffffff;
            padding: 40px;

            @include respond-to('m-768') {
                padding: 40px 0;
            }

            h2 {
                margin-top: 0;

                @include respond-to('m-768') {
                    padding: 0 40px;
                }
            }

            .bdrs {
                border-radius: $bdrs-normal;

                @include respond-to('m-768') {
                    box-shadow: initial;
                    border-radius: initial;
                }

                &:has(.oops) {
                    box-shadow: initial;
                    border-radius: initial;
                    width: fit-content;
                }
            }
        }
    }
}
