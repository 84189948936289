#funnel-scope {
    #ghost-container {
        container-type: inline-size;
        min-width: 300px;
        width: 100%;

        @include respond-to('m-768') {
            min-width: initial;
        }

        &.hidden {
            display: none;
        }

        .ghost-form {
            padding: 40px 42px;
            @include flex(row, flex-end, space-between);
            gap: 24px;

            @container (max-width: 990px) {
                @include flex(column);
                justify-content: flex-end;
            }

            @include respond-to('m-768') {
                @include flex(column);
                justify-content: flex-end;
            }

            .fields {
                width: 100%;
                display: flex;
                gap: 20px;

                @container (max-width: 990px) {
                    @include flex(column);
                }

                @include respond-to('m-768') {
                    @include flex(column);
                }

                .block {
                    flex-grow: 1;
                    @include flex(column);
                    gap: 16px;

                    .pseudo-title-container {
                        padding-bottom: 2px;

                        .pseudo-title {
                            width: 100px;
                            height: 18px;
                            background-color: $color-input-bd;
                        }
                    }

                    .pseudo-select-button {
                        width: 250px;
                        height: 39px;
                        border-radius: $bdrs-normal;
                        background-color: $color-input-bd;
                    }

                    .pseudo-field-container {
                        padding-top: 4px;

                        &.vertical-display-only {
                            display: none;

                            @include respond-to('d-1024') {
                                display: block;
                            }
                        }

                        .pseudo-field {
                            width: 100%;
                            height: 46px;
                            border-radius: $bdrs-normal;
                            background-color: $color-input-bd;
                        }
                    }
                }
            }

            .submit {
                width: 100%;
                @include flex(row, null, flex-end);

                .pseudo-button {
                    width: 100px;
                    height: 39px;
                    border-radius: $bdrs-normal;
                    background-color: $color-input-bd;
                }
            }
        }
    }

    &.oops {
        .iframe {
            display: none;
        }

        .shipping-app-link {
            margin: 0;
            display: block;
        }
    }

    .shipping-app-link {
        display: none;
    }

    .iframe {
        @include respond-to('m-768') {
            border-radius: initial;
            box-shadow: initial;
        }

        iframe {
            border: 0;
            width: 100%;
            display: none;

            &.visible {
                display: block;
            }
        }
    }
}

.ghost {
    opacity: 0.7;
    animation: ghost-loading 1s linear infinite alternate;
}

@keyframes ghost-loading {
    0% {
        opacity: 0.1;
    }

    100% {
        opacity: 0.3;
    }
}