.automation {

    a {
        text-decoration: none;
    }

    @include respond-to('m-768') {
        background-position: initial;
    }

    .page-content {
        background-image: url('images/background/automation.jpg');
        background-size: cover;
        background-position-y: -160px;

        .pseudo-card {
            padding: 40px;
            border-radius: $bdrs-normal;

            @include respond-to('m-768') {
                border-radius: initial;
            }
        }

        .top {
            padding: 40px;

            @include respond-to('m-768') {
                padding: 40px 0 0;
            }

            h1, p {
                color: #ffffff;
            }

            h1, .cta-block {
                @include respond-to('m-768') {
                    padding: 0 40px;
                }
            }

            .flex1 {
                gap: 40px;
                @include flex-row-to-column('m-768', flex-start, flex-start);

                .cta-block, .arguments {
                    width: calc(50% - 20px);

                    @include respond-to('m-768') {
                        width: initial;
                    }
                }

                .arguments {
                    background-color: rgba(16, 60, 107, 0.6);

                    @include respond-to('m-768') {
                        width: calc(100% - 80px);
                    }

                    .intro {
                        margin-top: 0;
                        padding-bottom: 16px;
                    }

                    .argument1 {
                        .flex2 {
                            @include flex(column, flex-start, flex-start);
                            padding-bottom: 16px;

                            .icons {
                                @include flex(row, center, flex-start);

                                img {
                                    max-width: 40px;
                                    height: auto;

                                    @include respond-to('m-768') {
                                        max-width: 24px;
                                    }
                                }

                                .plus {
                                    padding: 0 8px;
                                }
                            }

                            .text {
                                padding-top: 8px;
                            }
                        }
                    }

                    .argument2 {

                        @include flex(column, flex-start, flex-start);
                        margin-bottom: 0;

                        img {
                            width: 40px;
                            height: 40px;
                            opacity: 1;

                            @include respond-to('m-768') {
                                width: 24px;
                                height: 24px;
                            }
                        }

                        .text {
                            padding-top: 8px;
                        }
                    }
                }

                .cta-block {
                    .text {
                        margin-top: 0;
                        padding-top: 40px;
                    }

                    .link {
                        padding-top: 16px;
                    }
                }
            }
        }

        .bottom {
            background-color: #ffffff;
            padding: 40px;

            h2 {
                margin-top: 0;

                img {
                    height: 24px;
                    width: auto;
                    margin-left: 16px;
                    opacity: 0.7;
                }
            }
        }
    }
}
