.send-pallet {
    background-image: url('images/background/send-pallet.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    @include respond-to('t-992') {
        background-size: 100%;
    }

    @include respond-to('m-768') {
        background-position-y: 56px;
    }

    .page-content {

        .top {
            padding: 40px;

            h1, h2 {
                color: #ffffff;
            }

            h2 {
                @include flex(row, flex-end, flex-start);
                padding: 40px 0 0;
                position: relative;

                @include respond-to('m-768') {
                    padding: 0;
                }

                img {
                    width: 48px;
                    height: auto;
                    opacity: 0.7;
                }

                span {
                    margin-left: -16px;
                    z-index: 1;
                }
            }
        }

        .funnel {
            background-color: #ffffff;

            &:has(.oops) {
                background-color: transparent;
                padding: 0 40px 40px 40px;
            }

            .container-iframe-funnel {
                width: 100%;
                max-width: 1200px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .bottom {
            color: #ffffff;
            padding: 40px;

            @media only screen and (max-width: 1090px) {
                background-color: #020E21;
            }

            h2 {
                margin-top: 0;
            }

            .flex {
                @include flex(row, flex-start, space-between);
                gap: 40px;

                @include respond-to('t-992') {
                    flex-direction: column;
                    gap: 0;
                }

                p {
                    width: 50%;
                    @include respond-to('t-992') {
                        align-self: center;
                    }

                    @include respond-to('m-768') {
                        width: 100%;
                    }
                }

                .carriers {
                    @include respond-to('t-992') {
                        align-self: flex-end;
                        padding-top: 32px;
                    }

                    @include respond-to('m-768') {
                        width: 100%;
                        //padding-top: 32px;
                    }

                    ul {
                        @include flex(row, center, space-between);
                        flex-wrap: wrap;
                        gap: 40px;
                        margin: 0;
                        padding: 0;

                        @media only screen and (max-width: 1090px) {
                            gap: 16px;
                        }

                        @include respond-to('m-768') {
                            justify-content: flex-start;
                            align-items: center;
                        }

                        li {
                            list-style-type: none;
                            width: 80px;
                            height: 80px;
                            padding: 0;
                            background-color: #ffffff;
                            border-radius: 50%;
                            overflow: hidden;
                            @include flex(row, center, center);

                            img {
                                max-width: 60px;
                                height: auto;

                                &.sodexi {
                                    max-width: 90%;
                                }

                                &.dhl {
                                    max-width: 112px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
